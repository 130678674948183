import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your page components
import Home from './Home';
import About from './pages/about';
import Project3 from './pages/project3';
import Project4 from './pages/destiny-medals';
import Project2 from './pages/project2';
import Project1 from './pages/project1';
import SteelSeries from './pages/steelseries';
import NotFound from './pages/404';

const AppRoutes = () => {
  return (
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/project3" element={<Project3 />} /> */}
        {/* <Route path="/destiny-medals" element={<Project4 />} /> */}
        {/* <Route path="/project2" element={<Project1 />} />
        <Route path="/project1" element={<Project1 />} /> */}
        {/*<Route path="/steelseries" element={<SteelSeries />} />*/}

         {/*404 - Page Not Found */}
         {/*This route should always be last */}
        <Route element={NotFound} />
      </Routes>
  );
};

export default AppRoutes;
