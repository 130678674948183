import React from "react";
import { motion } from "framer-motion";
import styles from "./blackContainer.module.css";

/**
 * This container starts fully opaque (opacity=1),
 * then fades out after ~1.3s, so you see the page behind it.
 */
const containerVariants = {
  initial: { opacity: 1 },
  animate: {
    opacity: 0,
    transition: {
      delay: 1.3,     // Wait until the triangle finishes
      duration: 0.8,  // Then fade out
      ease: "easeInOut"
    }
  }
};

const BlackContainer = () => {
  return (
    <motion.div
      className={styles.overlay}
      variants={containerVariants}
      initial="initial"
      animate="animate"
    />
  );
};

export default BlackContainer;
