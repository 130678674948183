import React from "react";
import { motion } from "framer-motion";
import styles from "./blackTriangle.module.css";

/**
 * The black triangle that sits in the bottom-right corner, 
 * then slides downward & rotates, revealing the corner behind it.
 */
const triangleVariants = {
  initial: {
    y: 0,
    rotate: 0
  },
  animate: {
    y: 200,
    rotate: 30,
    transition: {
      duration: 1.2,
      ease: "easeInOut"
    }
  }
};

const BlackTriangle = () => {
  return (
    <motion.div
      className={styles.triangle}
      variants={triangleVariants}
      initial="initial"
      animate="animate"
    />
  );
};

export default BlackTriangle;
