import React, { useState, useEffect } from "react";
import styles from "./heroSlideshow.module.css";
import LOGO from "../images/injuro/LOGO.png";

const words = ["ARCHITECTURE", "INTERIOR", "DESIGN", "IMAGE"];

// Build a flat list of { wordIndex, letterIndex, char } objects
const flattenedLetters = words
  .map((word, wIdx) =>
    word.split("").map((char, cIdx) => ({
      wordIndex: wIdx,
      letterIndex: cIdx,
      char,
    }))
  )
  .flat();

const HeroSlideshow = ({ images = [], interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // We'll store which flattenedLetter indexes are currently visible.
  // e.g. visibleLetterIndexes = [0,1,2] means we've revealed the first 3 letters of the entire sequence.
  const [visibleLetterIndexes, setVisibleLetterIndexes] = useState([]);
  // This increments each time we finish a cycle so we can restart the animation
  const [animationCycle, setAnimationCycle] = useState(0);

  // Slideshow effect
  useEffect(() => {
    if (!images.length) return;

    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, interval);

    return () => clearInterval(timer);
  }, [images, interval]);

  // Letter-appearance effect
  useEffect(() => {
    setVisibleLetterIndexes([]); // start hidden on each cycle
    let i = 0;
    const totalLetters = flattenedLetters.length;

    // Reveal one letter every 300ms
    const letterTimer = setInterval(() => {
      setVisibleLetterIndexes((prev) => [...prev, i]);
      i++;

      // If we've revealed all letters, clear them out after a delay
      if (i >= totalLetters) {
        clearInterval(letterTimer);
        setTimeout(() => {
          setVisibleLetterIndexes([]); // Hide all letters (dissolve effect)
          // Then queue a new cycle
          setAnimationCycle((prev) => prev + 1);
        }, 2000); // 2-second delay with all letters visible
      }
    }, 100);

    return () => clearInterval(letterTimer);
  }, [animationCycle]);

  return (
    <div className={styles.heroSlideshowContainer}>
      {/* Slideshow side */}
      <div className={styles.slideshowSide}>
        {images.map((img, idx) => (
          <div
            key={idx}
            className={styles.slideWrapper}
            style={{ opacity: idx === currentIndex ? 1 : 0 }}
          >
            <img src={img} alt={`Slide ${idx}`} className={styles.slideImage} />
            <div className={styles.gradientOverlay} />
          </div>
        ))}
      </div>

      {/* Text side */}
      <div className={styles.textSide}>
        <div className={styles.verticalText}>
        {words.map((word, wIdx) => (
          <div key={wIdx} className={styles.verticalNames}>
            {word.split("").map((letter, cIdx) => {
              const globalIndex = flattenedLetters.findIndex(
                (item) => item.wordIndex === wIdx && item.letterIndex === cIdx
              );
              const isVisible = visibleLetterIndexes.includes(globalIndex);

              return (
                <span
                  key={cIdx}
                  className={isVisible ? styles.visibleLetter : styles.hiddenLetter}
                >
                  {letter}
                </span>
              );
            })}

            {/* Put contactInfo at the bottom of the second column (wIdx = 1 => 'INTERIOR') */}
            {wIdx === 1 && (
              <div className={styles.contactInfo}>
                <p>VIALE BEATRICE D&apos;ESTE, 23 - MILANO</p>
                <p>
                  <a href="mailto:info@injuro.xyz">INFO@INJURO.XYZ</a>
                </p>
                <p>
                  <a
                    href="https://instagram.com/injurostudio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @INJUROSTUDIO
                  </a>
                </p>
              </div>
            )}
          </div>
))}

        </div>

        <div className={styles.triangleWrapper}>
          {/* Existing big pink or white triangle */}
          <div className={styles.triangle}></div>
          {/* The existing Injuro logo */}
          <img src={LOGO} alt="Injuro Logo" className={styles.triangleLogo} />
        </div>
      </div>
    </div>
  );
};

export default HeroSlideshow;
